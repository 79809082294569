const windowObj : any = window;
SystemJS.config({
    baseURL: windowObj.contextPath,
    warnings: true,
    meta: {
		'*' : {
			nonce:windowObj.document.getElementsByTagName('script')[0].nonce,
		},
        angular: {
            format: 'global',
            exports: 'angular',
            build: false
        },
        rx: {
             exports: 'Rx'
        },
        'angular-mocks': {
            deps: ['angular'],
            build: false
        },
        'angular-animate': {
            deps: ['angular'],
            build: false
        },
        'ui-bootstrap': {
            deps: ['angular-animate'],
            build: false
        },
        'translate': {
            deps: ['angular'],
            build: false
        },
        'angular-translate-loader-url': {
            deps: ['translate'],
            build: false
        },
        'angular-translate-loader-static-files': {
            deps: ['translate'],
            build: false
        },
        'angular-translate-interpolation-messageformat': {
            deps: ['angular-sanitize', 'translate', 'messageformat'],
            build: false
        },
        'messageformat': {
            exports: 'MessageFormat',
            build: false
        },
        'angular-sanitize': {
            deps: ['angular'],
            build: false
        },
        'dynamicLocale': {
            deps: ['angular'],
            build: false
        },
        'LanguagesAndDateFormats': {
            build: false
        },
        'urlConstants': {
            build: false
        },
        'adminConfigConstants': {
            build: false
        },
        'themeTemplate': {
            build: false
        },
        'angular-ui-router': {
            deps: ['angular']
        },
        'ng-toast': {
            deps: ['angular']
        },
        'ui-select': {
            deps: ['angular']
        },
        'angular-messages': {
            deps: ['angular'],
            build: false
        },
        'BaseStore': {
            build: false
        },
        'booking-engine/*': {
            build: false
        },
        'angular-drag-and-drop-lists': {
            deps: ['ios-drag-drop']
        },
        'ios-drag-drop': {
            deps: ['angular']
        },
        'angular-dropdown-multiselect': {
            deps: ['angular']
        },
        'angular-media-queries': {
            deps: ['angular']
        },
        'written-number': {
            build: false
        },
        'tinymce': {
            deps: ['angular']
        },
        'angular-ui-tinymce': {
            deps: ['angular', 'tinymce', 'ui-tinymce-theme', 'tinymce-plugins']
        },
		'angular-ui-mask': {
            deps: ['angular']
        },
        'angular-recaptcha': {
            deps: ['angular']
        },
	'angular-ui-grid':{
		deps: ['angular']
	}
    },
    map: {
        angular: 'booking-engine/lib/angular/angular.js',
        'angular-mocks': 'booking-engine/lib/angular-mocks/angular-mocks.js',
        'ui-bootstrap': 'booking-engine/lib/angular-ui/ui-bootstrap-tpls.js',
        'angular-animate': 'booking-engine/lib/angular/angular-animate.js',
        rx: 'booking-engine/lib/rx/rx.all.js',
        BaseStore: 'booking-engine/client/core/flux/base-store.js',
        'translate': 'booking-engine/lib/angular-translate/angular-translate.js',
        'messageformat': 'booking-engine/lib/messageformat/messageformat.js',
        'angular-sanitize': 'booking-engine/lib/angular-sanitize/angular-sanitize.js',
        'angular-translate-interpolation-messageformat': 'booking-engine/lib/angular-translate/angular-translate-interpolation-messageformat/angular-translate-interpolation-messageformat.js',
        'angular-translate-loader-url': 'booking-engine/lib/angular-translate/angular-translate-loader-url/angular-translate-loader-url.js',
        'angular-translate-loader-static-files': 'booking-engine/lib/angular-translate/angular-translate-loader-static-files/angular-translate-loader-static-files.js',
        'dynamicLocale': 'booking-engine/lib/angular-dynamic-locale/tmhDynamicLocale.js',
        'angular-ui-router': 'booking-engine/lib/angular-ui-router/angular-ui-router.js',
        'ng-toast': 'lib/ng-toast/ngToast.js',
        'ng-google-chart': 'lib/ng-google-chart/ng-google-chart.js',
        'ui-select': 'lib/angular-ui-select/select.js',
        'angular-ui-grid': 'lib/angular-ui-grid/ui-grid.js',
        'angular-messages': 'booking-engine/lib/angular-messages/angular-messages.js',
        'angular-drag-and-drop-lists': 'booking-engine/lib/angular-drag-and-drop-lists/angular-drag-and-drop-lists.js',
        'ios-drag-drop': 'booking-engine/lib/drag-drop-webkit-mobile/ios-drag-drop.js',
        'angular-dropdown-multiselect': 'booking-engine/lib/angular-dropdown-multiselect/angular-dropdown-multiselect.js',      
        'framework.module' : 'booking-engine/client/components/framework.module',
        'rzslider': 'booking-engine/lib/rz-slider/rzslider.js',
        'analytics': 'booking-engine/lib/analytics/booking-engine-analytics.js',
        'angular-media-queries':'booking-engine/lib/angular-media-queries/match-media.js',
        'written-number': 'booking-engine/lib/js-written-number/written-number.js',
        'ui-tinymce-theme': 'booking-engine/lib/tinymce/themes/silver/theme.min.js',
        'tinymce': 'booking-engine/lib/tinymce/tinymce-core.min.js',
        'angular-ui-tinymce': 'booking-engine/lib/angular-ui-tinymce/tinymce.min.js',
        'tinymce-plugins': 'booking-engine/client/components/angular-tinymce-plugin-loader.js',
		'angular-ui-mask': 'booking-engine/lib/angular-ui-mask/mask.js',
		'datetime-difference': 'booking-engine/lib/datetime-difference/index.js',
		'angular-cookies': 'booking-engine/lib/angular-cookies/angular-cookies.js',
		'google-libphonenumber': 'booking-engine/lib/libphonenumber/libphonenumber.js',
		'angular-recaptcha': 'booking-engine/lib/angular-recaptcha/angular-recaptcha.js',
        'beforeunload-request': 'booking-engine/lib/beforeunload-request/beforeunload-request.min.js',
    },
    paths: {
        "booking-engine/lib/": windowObj.platformUrl + "/lib/",
        "booking-engine/client/": windowObj.platformUrl + "/client/",
        "tinymce-plugin/" : windowObj.platformUrl + "/lib/tinymce/",
    },
    packages: {
        'client' : {
            defaultExtension: 'js'
        },
        'booking-engine/client' : {
            defaultExtension: 'js',
        }
    }   
});
